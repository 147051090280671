import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

export function Testimonial() {
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section className="text-blue-night font-body w-full bg-blue-egg">
        <div className="container mx-auto flex px-5 lg:py-24 py-12 md:flex-row flex-col items-center">
          <div className="flex flex-col w-full items-center text-center">
            <h1 className="md:text-xxl md:w-2/3 text-xl mb-4 text-center font-bold tracking-tightest">
              Các thương hiệu hàng đầu đang tin tưởng và lựa chọn cokhach.vn
            </h1>
            <div className="md:w-10/12 flex flex-wrap flex-row justify-between items-center">
              <div className="hover:shadow-sm sm:w-40 w-20 h-24 rounded-lg flex items-center">
                <img
                  className="m-auto"
                  src="https://cdn.cokhach.vn/pimage/brand-logo/logo-vinhome.png"
                  alt="features img"
                  width="127"
                  height="57"
                />
              </div>
              <div className="hover:shadow-sm sm:w-40 w-20 h-24 rounded-lg flex items-center">
                <img
                  className="m-auto"
                  src="https://cdn.cokhach.vn/pimage/brand-logo/logo-cenland.png"
                  alt="features img"
                  width="120"
                  height="30"
                />
              </div>
              <div className="hover:shadow-sm sm:w-40 w-20 h-24 rounded-lg flex items-center">
                <img
                  className="m-auto"
                  src="https://cdn.cokhach.vn/pimage/brand-logo/logo-dxmb.png"
                  alt="features img"
                  width="126"
                  height="55"
                />
              </div>
              <div className="hover:shadow-sm w-40 h-24 rounded-lg flex items-center">
                <img
                  className="m-auto"
                  src="https://cdn.cokhach.vn/pimage/brand-logo/logo-sunshine.png"
                  alt="features img"
                  width="95"
                  height="64"
                />
              </div>
              <div className="hover:shadow-sm w-40 h-24 rounded-lg flex items-center">
                <img
                  className="m-auto"
                  src="https://cdn.cokhach.vn/pimage/brand-logo/logo-kimthinh.png"
                  alt="features img"
                  width="75"
                  height="54"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}

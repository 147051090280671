import React, { ReactElement } from 'react';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { AreYou } from '../components/index/AreYou';
import { CTAAffordable } from '../components/index/CTA';
import { Features } from '../components/index/Features';
import { Hero } from '../components/index/Hero';
import { Pricing } from '../components/index/Pricing';
import { Testimonial } from '../components/index/Testimonial';
import { WhyCoKhach } from '../components/index/WhyCoKhach';
import { Meta } from '../components/Meta';

interface Props { }

function Index(_props: Props): ReactElement {
  return (
    <>
      <Meta title="#1 công cụ marketing cho môi giới bất động sản Việt Nam"
        description="Tăng tốc chiến dịch quảng cáo và tỷ lệ chuyển đổi khách nét. Trọn bộ công cụ marketing tiên phong cho sales bất động sản. | Bắt đầu sử dụng miễn phí!" />
      <Header />
      <Hero />
      {/* <ValueProposition /> */}
      <Features />
      <CTAAffordable />
      <WhyCoKhach />
      <AreYou />
      <Testimonial />
      <Pricing />
      {/* <CTA2 /> */}
      <Footer />
    </>
  )
}

export default Index

import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { SectionTitle } from "../parts/Title"

export function Features() {
  const [tabActive, setTabActive] = React.useState(0)
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section id="section_feature" className="text-blue-night font-body">
        <div className="container lg:pt-24 pt-8 mx-auto">
          <div className="lg:w-10/12 lg:px-0 px-4 mx-auto">
            <SectionTitle>Hệ thống tính năng chuyên biệt</SectionTitle>
          </div>
          <div className="flex flex-wrap lg:p-0 lg:pb-16 p-4 mx-auto relative overflow-hidden">
            <div className="lg:w-10/12 flex md:flex-row flex-col md:mx-auto">
              <div className="md:w-1/3 flex w-full">
                <div className="flex-grow">
                  <a
                    id="feature_website"
                    onClick={(e) => {
                      setTabActive(0)
                    }}
                    href="#section_feature"
                    className={`${
                      tabActive === 0
                        ? "text-gradient bg-gradient-to-tr from-blue to-blue-light2"
                        : "text-gray-pinkish"
                    } cursor-pointer tracking-tightest xl:text-xxl lg:text-xl2 text-lg font-bold mb-2`}
                  >
                    Trình tạo landing page
                  </a>
                </div>
              </div>
              <div className="md:w-1/3 flex w-full">
                <div className="flex-grow">
                  <a
                    id="feature_adcreation"
                    onClick={(e) => {
                      setTabActive(1)
                    }}
                    href="#section_feature"
                    className={`${
                      tabActive === 1
                        ? "text-gradient bg-gradient-to-tr from-blue to-blue-light2"
                        : "text-gray-pinkish"
                    } cursor-pointer tracking-tightest xl:text-xxl lg:text-xl2 text-lg font-bold mb-2`}
                  >
                    Trình tạo quảng cáo
                  </a>
                </div>
              </div>
              <div className="md:w-1/3 flex w-full">
                <div className="flex-grow">
                  <a
                    id="feature_adoptimization"
                    onClick={(e) => {
                      setTabActive(2)
                    }}
                    href="#section_feature"
                    className={`${
                      tabActive === 2
                        ? "text-gradient bg-gradient-to-tr from-blue to-blue-light2"
                        : "text-gray-pinkish"
                    } cursor-pointer tracking-tightest xl:text-xxl lg:text-xl2 text-lg font-bold mb-2`}
                  >
                    Tối ưu quảng cáo
                  </a>
                </div>
              </div>
            </div>
            <div
              className={`w-full transition-all delay-200 transform ${
                tabActive === 0
                  ? "opacity-1 translate-x-0"
                  : "opacity-0 translate-x-16"
              }`}
            >
              <p className="lg:w-1/2 sm:h-24 md:h-36 py-4 md:mx-auto md:text-center leading-medium text-blue-night">
                Nền tảng thiết kế landing page thông qua thao tác kéo thả dễ sử
                dụng, thư viện 100+ mẫu template tối ưu cho ngành Bất động sản,
                tích hợp chat plugin, ghi hình phiên truy cập của khách thăm và
                các công cụ hỗ trợ chuyển đổi khác.
              </p>
              <div className="lg:w-10/12 m-auto">
                <img
                  className="w-full shadow-lg"
                  src="https://cdn.cokhach.vn/pimage/home-page/feature-builder.png"
                  alt="features img"
                  width="1020"
                  height="545"
                />
              </div>
            </div>
            <div
              className={`absolute z-0 md:top-14 top-28 md:inset-x-0 inset-x-4 transition-all delay-200 transform ${
                tabActive === 1
                  ? "opacity-1 translate-x-0"
                  : "opacity-0 translate-x-16"
              }`}
            >
              <p className="lg:w-1/2 sm:h-24 md:h-36 py-4 md:mx-auto md:text-center leading-medium text-blue-night">
                Tạo quảng cáo Google / Facebook như một nhà quảng cáo chuyên
                nghiệp chỉ với vài thao tác đơn giản.
              </p>
              <div className="lg:w-10/12 m-auto">
                <img
                  className="w-full shadow-lg"
                  src="https://cdn.cokhach.vn/pimage/home-page/feature-create-campaign.png"
                  alt="features img"
                  width="1020"
                  height="545"
                />
              </div>
            </div>
            <div
              className={`absolute z-0 md:top-14 top-28 md:inset-x-0 inset-x-4 transition-all delay-200 transform ${
                tabActive === 2
                  ? "opacity-1 translate-x-0"
                  : "opacity-0 translate-x-16"
              }`}
            >
              <p className="lg:w-1/2 sm:h-24 md:h-36 py-4 md:mx-auto md:text-center leading-medium text-blue-night">
                Hệ thống gợi ý quảng cáo thông minh nhanh chóng phát hiện vấn đề
                và đưa ra giải pháp tối ưu quảng cáo.
              </p>
              <div className="lg:w-10/12 m-auto">
                <img
                  className="w-full shadow-lg"
                  src="https://cdn.cokhach.vn/pimage/home-page/feature-ads-optime.png"
                  alt="features img"
                  width="1020"
                  height="545"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}

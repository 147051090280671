import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { SectionTitle } from "../parts/Title"

export function AreYou() {
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section className="text-blue-night sm:pt-24 pt-12 font-body bg-blue-100">
        <div className="container mx-auto">
          <div className="flex justify-center sm:mb-12 mb-4">
            <SectionTitle>Bạn là…</SectionTitle>
          </div>
          <div className="flex md:flex-row flex-col -mt-4">
            <div className="p-4 md:w-1/3 flex flex-col items-center">
              <div className="flex-grow">
                <h2 className="text-xl2 title-font font-bold mb-2">Sales</h2>
                <p className="leading-relaxed text-base">
                  Bạn không muốn tiêu tốn tiền bạc và thời gian cho những quảng
                  cáo không hiệu quả?{" "}
                  <span className="font-bold">cokhach.vn</span> sẽ giúp bạn tạo
                  và chạy quảng cáo thành thục như một nhà quảng cáo chuyên
                  nghiệp.
                </p>
                <a
                  id="areyou_sales"
                  className="mt-2 text-blue inline-flex font-medium items-center"
                  href="/sales"
                >
                  Tìm hiểu thêm
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex flex-col items-center">
              <div className="flex-grow">
                <h2 className="text-xl2 title-font font-bold mb-2">Marketer</h2>
                <p className="leading-relaxed text-base">
                  Bạn đang trả một khoản chi phí không nhỏ cho nhiều công cụ để
                  quản lý quảng cáo của mình?{" "}
                  <span className="font-bold">cokhach.vn</span> có tất cả những
                  tính năng bạn cần với mức giá siêu tiết kiệm!
                </p>
                <a
                  id="areyou_marketer"
                  className="mt-2 text-blue inline-flex font-medium items-center"
                  href="/marketers"
                >
                  Tìm hiểu thêm
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex flex-col tems-center">
              <div className="flex-grow">
                <h2 className="text-xl2 title-font font-bold mb-2">Manager</h2>
                <p className="leading-relaxed text-base">
                  Bạn đang cần hỗ trợ trong việc quản lý & tăng năng suất cho
                  đội nhóm? <span className="font-bold">cokhach.vn</span> sẽ
                  thay bạn đào tạo nhân viên và báo cáo năng suất chi tiết, giúp
                  bạn phát hiện và cải thiện kịp thời.
                </p>
                <a
                  id="areyou_manager"
                  className="mt-2 text-blue inline-flex font-medium items-center"
                  href="/managers"
                >
                  Tìm hiểu thêm
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mx-4">
            <img
              className="m-auto md:mb-0 mb-12"
              src="https://cdn.cokhach.vn/pimage/are-you.png"
              alt="features img"
              width="1032"
              height="684"
            />
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}

import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { SectionTitle } from "../parts/Title"

export function WhyCoKhach() {
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section className="text-blue-night font-body bg-blue-egg">
        <div className="container pt-14 mx-auto">
          <div className="flex flex-wrap">
            <div className="lg:w-1/3 md:w-1/2 p-4">
              <SectionTitle>Lựa chọn số 1 cho ngành Bất động sản</SectionTitle>
              <div className="block">
                <h2 className="text-blue-night text-lg font-bold mt-5">
                  Đơn giản và dễ sử dụng
                </h2>
                <p className="tracking-tight text-base mt-1">
                  Bất kỳ ai cũng có thể tạo landing page và bắt đầu chạy quảng
                  cáo dễ dàng qua <span className="font-bold">cokhach.vn</span>.
                </p>
                <h2 className="text-blue-night text-lg font-bold mt-8">
                  Tiết kiệm chi phí
                </h2>
                <p className="tracking-tight text-base">
                  Chi trả duy nhất cho một công cụ phục vụ tất cả các công đoạn
                  trong quá trình bán hàng của bạn, từ tìm kiếm đến chăm sóc,
                  nuôi dưỡng khách hàng.
                </p>
                <h2 className="text-blue-night text-lg font-bold mt-8">
                  Hiệu quả
                </h2>
                <p className="tracking-tight text-base">
                  <span className="font-bold">cokhach.vn</span> được phát triển
                  chuyên biệt cho ngành Bất động sản Việt Nam, dựa trên công
                  nghệ và kinh nghiệm của top marketer trong ngành.
                </p>
              </div>
            </div>
            <div className="lg:w-2/3 md:w-1/2 md:-mt-14">
              <img
                className="m-auto"
                src="https://cdn.cokhach.vn/pimage/best-choise.png"
                alt="features img"
                width="730"
                height="624"
              />
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}

import React from "react"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import ScrollAnimation from "react-animate-on-scroll"
import { REACT_APP_DASHBOARD_URL } from "../../constants"
import LPVideo from "../../videos/LP_intro.mp4"
import LPVideoWebm from "../../videos/LP_intro.webm"

export function Hero() {
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section className="text-blue-night bg-blue-light rounded-b-large font-body">
        <div className="container mx-auto flex pt-24 md:pt-32 md:flex-row flex-col items-center">
          <div className="lg:w-5/12 lg:pr-8 md:pr-8 text-left md:mb-0 p-4 flex flex-col md:mb-16 items-start">
            <h1 className="md:text-xxl xl:text-xxxl text-xl3 leading-normal mb-4 md:mb-6 text-blue-night font-bold">
              Thêm{" "}
              <span className="bg-blue text-white rounded-md">Khách Nét</span>
              <div className="my-1">
                Giảm{" "}
                <span className="bg-blue text-white rounded-md">Chi Phí</span>
              </div>
              Không đòi hỏi{" "}
              <span className="bg-blue text-white rounded-md">Kỹ Năng</span>
            </h1>
            <p className="mb-4 md:mb-8 md:text-md text-base leading-normal">
              <span className="font-bold">cokhach.vn</span> là công cụ marketing
              toàn diện dành riêng cho ngành Bất động sản Việt Nam, hỗ trợ tạo
              và quản lý quảng cáo, tối ưu chuyển đổi một cách dễ dàng.
              <br />
              <br />
              Không còn lo tốn kém chi phí cho quảng cáo kém chất lượng. Dùng là
              có khách!
            </p>
            <div className="flex flex-col justify-center md:w-auto w-full">
              <Link
                id="hero_singup"
                to={`${REACT_APP_DASHBOARD_URL}`}
                target="_blank"
                className="inline-flex font-medium justify-center text-white bg-pink-medium border-0 py-3 md:py-4 px-6 focus:outline-none hover:bg-pink-500 rounded-md text-base md:text-lg"
              >
                Bắt đầu sử dụng miễn phí
              </Link>
              <button
                className="font-medium underline md:text-md lg:mt-6 mt-3 mb-3 text-base md:text-left text-center"
                onClick={() => scrollTo("#pricing")}
              >
                Xem Bảng giá
              </button>
            </div>
          </div>
          <div className="lg:w-7/12 bg-hero-background bg-cover bg-no-repeat bg-top mx-4 mb-12 md:mb-0 md:mx-0">
            <video
              className="mx-auto px-2 xl:mb-60 lg:pb-2 lg:mb-48 mt-2 mb-28"
              controls={false}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
            >
              <source src={LPVideo} type="video/mp4" />
              <source src={LPVideoWebm} type="video/webm" />
              <p>
                Trình duyệt không hổ trợ HTML5 video. Đây là{" "}
                <a href="LPVideoWebm">link đến file video</a>
              </p>
            </video>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}
